import Modal from "react-bootstrap/Modal";
import logo from "./logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import "./App.css";
import { Button, Container } from "reactstrap";
import AutoForm from "./forms/AutoForm";
import Ship from "./components/Ship";
import AllShips from "./components/AllShips";
import ImportFromGoogleCalendarModal from "./forms/ImportFromGoogleCalendarModal";
import {
  COMMAND_IMPORT_GOOGLE_CALENDAR,
  COMMAND_CREATE_AUTO,
  COMMAND_UPDATE_AUTO,
  renderHTagWithText,
  renderPTagWithText,
  TAG_H,
  COMMAND_CREATE_SHIP,
} from "./util/Util";
import Scheduler from "./pages/Scheduler";
import DayPicker from "./pages/DayPicker";
import { useState, useEffect, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import ActivationFailed from "./components/ActivationFailed";
import { SELECT_SERVICE } from "./actions/types";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { JustifyLeft } from "react-bootstrap-icons";
import userService from "./services/user.service";
import UserService from "./services/user.service";

function App() {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [show, setShow] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let location = useLocation();

  useEffect(() => {
    if (["/login", "/register"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));

      // глобальный перехватчик исключений
      // UserService.sendError({ er: "error", id: "id" }).then((response) => {
      //   alert("Произошла ошибка");
      // });
      // const handleErrors = (error, info) => {
      //   console.error("Uncaught error:", error, info);
      // };

      // window.addEventListener("error", handleErrors);
      // return () => {
      //   window.removeEventListener("error", handleErrors);
      // };
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }
  }, [currentUser]);
  const [currentDate, setCurrentDate] = useState(undefined);
  const [modalShow, setModalShow] = useState(false);
  const [props, setProps] = useState();

  const hideMenu = () => {
    document.getElementById("navbarSupportedContent").className =
      "navbar-collapse top_nav collapse";
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <Link to={"/"} className="navbar-brand navbar-brand-custom">
            VELUM
          </Link>
          {currentUser && (
            <Link to={"/profile"} className="nav-link">
              {currentUser.username}
            </Link>
          )}
          <button
            className="navbar-toggler"
            style={{ marginRight: "5px !important" }}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse top_nav"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mx-auto ">
              {showModeratorBoard && (
                <li className="nav-item">
                  <Link to={"/mod"} className="nav-link">
                    Модератор
                  </Link>
                </li>
              )}

              {showAdminBoard && (
                <li className="nav-item">
                  <Link to={"/admin"} className="nav-link">
                    Администратор
                  </Link>
                </li>
              )}

              {currentUser && (
                <li className="nav-item">
                  <Link
                    to={"/calendar"}
                    className="nav-link"
                    onClick={hideMenu}
                  >
                    Календарь
                  </Link>
                  <Link
                    to={"/my_ships"}
                    className="nav-link"
                    onClick={hideMenu}
                  >
                    Мои суда
                  </Link>
                  <Link
                    to={"/ship"}
                    className="nav-link"
                    onClick={(e) => {
                      dispatch({
                        type: SELECT_SERVICE,
                        payload: { id: undefined, command: "CREATE" },
                      });
                      setShow("SHIP");
                      hideMenu();
                    }}
                  >
                    Добавить судно
                  </Link>
                  <Link to={"/profile"} className="nav-link" onClick={hideMenu}>
                    Профиль
                  </Link>
                </li>
              )}
            </ul>
            {currentUser ? (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a href="/login" className="nav-link" onClick={logOut}>
                    Выйти
                  </a>
                </li>
              </div>
            ) : (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/login"} className="nav-link">
                    Войти
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/register"} className="nav-link">
                    Регистрация
                  </Link>
                </li>
              </div>
            )}
          </div>
        </div>
      </nav>
      <div className="container mt-3">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/activation_failed"
            element={
              <ActivationFailed content="Что-то пошло не так ... Ваша регистрация закончилась неудачно, попробуйте снова" />
            }
          />

          <Route
            path="/calendar"
            element={
              <Scheduler
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
              />
            }
          />
          <Route path="/my_ships" element={<AllShips />} />
          {/* {show === "SHIP" &&  */}
          <Route path="/ship" element={<Ship />} />
          {/* } */}

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          {/* <Route path="/user" element={<BoardUser />} /> */}
          {/* <Route path="/mod" element={<BoardModerator />} /> */}
          {/* <Route path="/admin" element={<BoardAdmin />} /> */}
        </Routes>
      </div>

      {/* <div style={{ position: "relative" }}>
        <Scheduler currentDate={currentDate} setCurrentDate={setCurrentDate} />
      </div> */}

      {/* <div style={{ position: "relative" }}>
          <CreateBoatForm />
        </div> */}
      {/* <div style={{ position: "relative" }}>
          <AutoPage />
        </div> */}
      {/* <Button
        variant="info"
        onClick={() => setModalShow(COMMAND_IMPORT_GOOGLE_CALENDAR)}
      >
        Импорт из Google календаря
      </Button> */}
      {/* {modalShow === COMMAND_IMPORT_GOOGLE_CALENDAR && (
        <ImportFromGoogleCalendarModal></ImportFromGoogleCalendarModal>
      )} */}
      {/* </div> */}
    </>
  );
}

export default App;
// {
//   /* <div>
//   <CreateAutoForm />
// </div> */
// }
// {
//   /* {renderHTagWithText(TAG_H.H1, "VELUM", {
//   display: "flex",
//   justifyContent: "center",
//   marginTop: "10px",
//   marginBottom: "10px",
// })} */
// }
// {
//   /* <div style={{ position: "relative" }}>
//   <CreateAutoForm />
// </div> */
// }

// <Button
//   variant="primary"
//   onClick={() => setModalShow(COMMAND_UPDATE_AUTO)}
// >
//   Редактировать автотранспорт
// </Button>
// {modalShow === COMMAND_UPDATE_AUTO && (
//   <AutoForm
//     carId={1}
//     saveBtnTitle={"Сохранить"}
//     show_delete_btn=""
//     show={modalShow}
//     onHide={() => setModalShow(false)}
//     showFor={COMMAND_UPDATE_AUTO}
//   />
// )}
// <Button
//   variant="primary"
//   onClick={() => setModalShow(COMMAND_CREATE_AUTO)}
// >
//   Создать автотранспорт
// </Button>
// {modalShow === COMMAND_CREATE_AUTO && (
//   <AutoForm
//     carId={undefined}
//     saveBtnTitle={"Сохранить"}
//     show_delete_btn=""
//     show={modalShow}
//     onHide={() => setModalShow(false)}
//     showFor={COMMAND_CREATE_AUTO}
//   />
// )}

{
  /* <Button
  variant="primary"
  onClick={() => setModalShow(COMMAND_IMPORT_GOOGLE_CALENDAR)}
>
  Импорт из Google
</Button>;
{
  modalShow === COMMAND_IMPORT_GOOGLE_CALENDAR && (
    <ImportFromGoogleCalendarModal
      show={modalShow}
      onHide={() => setModalShow(false)}
    />
  );
} */
}
