import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import ProgressBar from "react-bootstrap/ProgressBar";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import {
  Eye,
  Check2Square,
  CheckSquareFill,
  EyeFill,
  Pencil,
  PencilSquare,
  Square,
  XCircle,
  XCircleFill,
  PlusCircle,
  PersonLinesFill,
  Check2All,
  CheckCircleFill,
} from "react-bootstrap-icons";
import DateTimePicker from "../pages/DateTimePicker";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { v4 as uuidv4 } from "uuid";
import Carousel from "react-bootstrap/Carousel";
import { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// Команды
const COMMAND_ALL = -1;
const COMMAND_AUTO_BRAND = 0;
const COMMAND_AUTO_MODEL = 1;
const COMMAND_AUTO_YEAR = 2;
const COMMAND_AUTO_DRIVE = 3;
const COMMAND_AUTO_BASING = 4;
const COMMAND_AUTO_ROUTES = 5;
const COMMAND_AUTO_CHILD_SEATS = 6;
const COMMAND_AUTO_BONUSES = 7;
const COMMAND_AUTO_LICENSE = 8;
const COMMAND_AUTO_PASSENGERS = 9;
const COMMAND_AUTO_MICROPHONE = 10;
const COMMAND_AUTO_ANIMALS = 11;
const COMMAND_AUTO_LUKE = 12;
const COMMAND_AUTO_PANORAMIC_ROOF = 13;
const COMMAND_AUTO_TOILET = 14;
const COMMAND_AUTO_INVALIDS = 15;
const COMMAND_BOAT_NAME = 16;
const COMMAND_BOAT_CABIN = 17;
const COMMAND_BOAT_SPEED = 18;
const COMMAND_BOAT_TOILET = 19;
const COMMAND_BOAT_REGISTRATION = 20;
const COMMAND_BOAT_SAFETYS = 21;
const COMMAND_CREATE_AUTO = 22;
const COMMAND_UPDATE_AUTO = 23;
const COMMAND_IMPORT_GOOGLE_CALENDAR = 24;
const COMMAND_SHIP_REGISTRATION = 25;
const COMMAND_BOAT_MICROPHONE = 26;
const COMMAND_CREATE_SHIP = 27;
const COMMAND_UPDATE_SHIP = 28;
const COMMAND_BOAT_WAVE_HIGH = 29;
const COMMAND_SHIP_SPEED = 30;
const COMMAND_VIEW_SHIP = 31;

const animatedComponents = makeAnimated();
const renderHTagWithText = (tag, text, style) => {
  switch (tag) {
    case TAG_H.H1:
      return (
        <div style={style}>
          <h1>{text}</h1>
        </div>
      );
    case TAG_H.H2:
      return (
        <div style={style}>
          <h2>{text}</h2>
        </div>
      );
    case TAG_H.H3:
      return (
        <div style={style}>
          <h3>{text}</h3>
        </div>
      );
    case TAG_H.H4:
      return (
        <div style={style}>
          <h4>{text}</h4>
        </div>
      );
    case TAG_H.H5:
      return (
        <div style={style}>
          <h5>{text}</h5>
        </div>
      );
    case TAG_H.H6:
      return (
        <div style={style}>
          <h6>{text}</h6>
        </div>
      );
  }
};

const renderDateField = (label, dateTime, updateFunc, eventId, command) => {
  return (
    <Form.Group as={Row} className="mb-3">
      <Form.Label column sm="2" style={{ width: "35%" }}>
        {label}
      </Form.Label>
      <Col sm="10" style={{ width: "65%" }}>
        <DateTimePicker
          dateTime={dateTime}
          update={updateFunc}
          eventId={eventId}
          command={command}
        />
      </Col>
    </Form.Group>
  );
};
const renderPTagWithText = (text, style) => {
  return (
    <div style={style}>
      <p>{text}</p>
    </div>
  );
};

const renderButton = (buttonStyle, text, style, disabled) => {
  return (
    <button
      type="button"
      className={buttonStyle}
      style={style}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

const TAG_H = { H1: 1, H2: 2, H3: 3, H4: 4, H5: 5, H6: 6 };

const BUTTON_STYLE = {
  PRIMARY: "btn btn-primary",
  SECONDARY: "btn btn-secondary",
  SUCCESS: "btn btn-success",
  DANGER: "btn btn-danger",
  WARNING: "btn btn-warning",
  INFO: "btn btn-info",
  LIGHT: "btn btn-light",
  DARK: "btn btn-dark",
  LINK: "btn btn-link",
};

const filterColumnedValues = (
  character,
  setFilteredValuesFunc,
  notFilteredValues,
  setTitleFunc
) => {
  setFilteredValuesFunc([]);
  if (character !== "") {
    notFilteredValues.map((list, i) => {
      list.map((el) => {
        if (el.toLowerCase().includes(character.toLowerCase())) {
          setFilteredValuesFunc((arr) => [...arr, el]);
        }
        setTitleFunc(character);
      });
    });
  } else {
    setFilteredValuesFunc([]);
    setTitleFunc("");
  }
};

const convertToDouble = (str) => {
  try {
    return Number(str);
  } catch (er) {
    if (str) {
      const ar = str.split(/[^0-9]/);
      const num = ar[0] + "." + ar[1];
      try {
        return Number(num);
      } catch (error) {
        console.error(error);
      }
    }
  }
};

const filterValues = (
  character,
  setFilteredValuesFunc,
  notFilteredValues,
  setTitleFunc
) => {
  setFilteredValuesFunc([]);
  if (character !== "") {
    notFilteredValues.map((el) => {
      if (el.toLowerCase().includes(character.toLowerCase())) {
        setFilteredValuesFunc((arr) => [...arr, el]);
      }
      setTitleFunc(character);
    });
  } else {
    setFilteredValuesFunc([]);
    setTitleFunc("");
  }
};

const renderFilteredValuesList = (values, style, command, setFieldFunc) => {
  return (
    <ul className="list-group" style={style}>
      {values.map((el) => (
        <li
          key={el}
          className="list-group-item"
          onClick={() => setFieldFunc(command, el)}
        >
          {el}
        </li>
      ))}
    </ul>
  );
};

const renderAllTableValues = (caption, renderFunc, onClickFunc) => {
  return (
    <div className="table-responsive-sm">
      {renderFunc()}
      <div>
        <button
          type="button"
          className="btn btn-link"
          onClick={() => onClickFunc()}
        >
          {caption}
        </button>
      </div>
    </div>
  );
};

const renderFilteredList = (caption, values, setValues, value, command) => {
  return (
    <div>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2" style={{ width: "35%" }}>
          {caption}
        </Form.Label>
        <Col sm="10" style={{ width: "65%" }}>
          <Form.Select onChange={(e) => setValues(command, e.target.value)}>
            <option>{value}</option>
            {values &&
              values.map((entity) => (
                <option key={entity.id} value={entity.label}>
                  {entity.label}
                </option>
              ))}
          </Form.Select>
        </Col>
      </Form.Group>
    </div>
  );
};
const renderTableValues = (values, command, selectAndHideFunc) => {
  return (
    <table className="table">
      <tbody>
        {values.map((list, i) => (
          <tr key={i}>
            {list.map((el, j) => (
              <td key={j} onClick={() => selectAndHideFunc(command, el)}>
                {el}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const renderListField = (
  command,
  title,
  value,
  clickValue,
  clickFunc,
  prevFieldValue,
  readOnly
) => {
  return (
    <div className="input-group mb-3">
      <span className="input-group-text">{title}</span>
      <input
        readOnly={readOnly}
        disabled={prevFieldValue}
        type="text"
        className="form-control"
        placeholder={value}
        onClick={(e) => clickFunc(!clickValue)}
      />
    </div>
  );
};

const renderCheckBoxField2 = (label, value, setValue, viewCommand, command) => {
  console.log();
  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2" style={{ width: "35%" }}>
          {label}
        </Form.Label>
        <Col sm="10" style={{ width: "65%" }}>
          <Form.Check
            disabled={viewCommand === "VIEW"}
            type="switch"
            id="custom-switch"
            label={value}
            defaultChecked={value ? value : undefined}
            // onChange={(e) => setValue(command, e)}
            onClick={(e) => {
              setValue(command, e);
            }}
          />
        </Col>
      </Form.Group>
    </>
  );
};

const renderCheckListBox = (
  label,
  valueList,
  val,
  setValue,
  command,
  modeCommand
) => {
  const set = new Set(val.map((el) => el.value));
  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2" style={{ width: "35%" }}>
          {label}
        </Form.Label>
        <Col sm="10" style={{ width: "65%" }}>
          {valueList.length > 0 && val && (
            <>
              {val.map((el) => (
                <span
                  className="badge text-bg-primary"
                  style={{ marginTop: modeCommand === "VIEW" ? "10px" : "0px" }}
                >
                  {el.label}
                </span>
              ))}
              {modeCommand !== "VIEW" && (
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setValue(command, e.target.value)}
                >
                  <option selected>Выбрать ...</option>
                  {valueList.map((el) => (
                    <option key={el.value} value={el.value}>
                      <p>{el.label}</p>
                    </option>
                  ))}
                </select>
              )}
            </>
          )}
        </Col>
      </Form.Group>
    </>
  );
};

const splitData = (data) => {
  return data.map((val) => {
    const split = val.split("###");
    return {
      order: split[0],
      title: split[1],
      name: split[2],
    };
  });
};

const fromEnum = (value, data) => {
  if (data && value) {
    return data.filter((v) => v.value === value)[0].label;
  }
};

const fromEnum2 = (value, data) => {
  if (data && value) {
    return data.filter((v) => v.label === value)[0].label;
  }
};

const fromMultiEnum = (selected, entries) => {
  if (entries && selected) {
    const res = [];
    for (const entry of entries) {
      for (const sel of selected) {
        if (entry.value === sel) {
          res.push(entry);
        }
      }
    }
    return res;
  }
};

const toEnum = (value, data) => {
  return data.filter((v) => {
    if (v.label === value) {
      return v.value;
    }
  })[0].value;
};

const toMultiEnum = (selected, entries) => {
  const res = [];
  for (const entry of entries) {
    for (const sel of selected) {
      if (entry.value === sel.value) {
        res.push(entry.value);
      }
    }
  }
  return res;
};

const renderCustomersLoading = (
  capacity,
  loading,
  setLoading,
  customerNotes,
  setCustomerNotes,
  showCutomersTable,
  setShowCutomersTable,
  addedCutomerNote,
  setAddedCutomerNote,
  addedCutomerCount,
  setAddedCutomerCount,
  viewCommand,
  setViewCommand,
  addCustomerAccumulator,
  setAddCustomerAccumulator,
  loadingUpdateFunc,
  customerNotesUpdateFunc
) => {
  const free = capacity - loading;
  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2" style={{ width: "35%" }}>
          Пассажиры
        </Form.Label>
        <Col
          sm="10"
          style={{ width: "65%", marginTop: "14px", marginBottom: "10px" }}
        >
          <>
            <ProgressBar>
              <ProgressBar
                striped
                variant={capacity === loading ? "success" : "secondary"}
                now={loading}
                min={0}
                max={capacity}
                key={1}
              />
              <ProgressBar
                striped
                variant={"success"}
                now={addCustomerAccumulator}
                min={0}
                max={capacity}
                key={2}
              />
            </ProgressBar>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "8px",
              }}
            >
              <Badge bg={capacity === loading ? "success" : "secondary"}>
                {capacity === loading ||
                capacity - loading - addCustomerAccumulator === 0
                  ? "Свободных мест нет"
                  : capacity - loading - addCustomerAccumulator === 1
                  ? "Осталось 1 место"
                  : capacity - loading - addCustomerAccumulator < 5
                  ? `Осталось ${
                      capacity - loading - addCustomerAccumulator
                    } места`
                  : `Осталось ${
                      capacity - loading - addCustomerAccumulator
                    } мест`}
              </Badge>
              <PersonLinesFill
                disabled={loading === 0}
                color="#28a745"
                size={24}
                style={{ marginLeft: "20px", cursor: "pointer" }}
                onClick={(e) => setShowCutomersTable(!showCutomersTable)}
              />
            </div>
            {showCutomersTable && (
              <div style={{ marginTop: "15px" }}>
                {customerNotes && customerNotes.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Badge bg={"success"} style={{ marginBottom: "6px" }}>
                      Список пассажиров
                    </Badge>
                  </div>
                )}
                {customerNotes && customerNotes.length > 0 && (
                  <Table striped bordered hover size="sm">
                    <tbody>
                      {customerNotes.map((cus, id) => (
                        <tr key={cus}>
                          <td>{cus}</td>
                          <td>
                            <XCircleFill
                              disabled={
                                loading === 0 || viewCommand !== undefined
                              }
                              color={
                                viewCommand !== undefined ? "grey" : "#dc3545"
                              }
                              size={18}
                              style={{ marginLeft: "20px", cursor: "pointer" }}
                              onClick={(e) => {
                                // notes from array
                                const str = customerNotes[id];

                                // extract customers
                                const count = extractCustomersFromNotes(str);

                                if (count > capacity) {
                                  return;
                                }

                                // update page customerNotes page state
                                setCustomerNotes((prev) => {
                                  const updated = prev.filter(
                                    (cn) => cn !== str
                                  );
                                  return updated;
                                });

                                // update event customerNotes
                                const cust = customerNotes.filter(
                                  (cn) => cn !== str
                                );
                                customerNotesUpdateFunc(cust);

                                // update addCustomerAccumulator and loading page state
                                if (count > addCustomerAccumulator) {
                                  setLoading(loading - count);
                                } else {
                                  setAddCustomerAccumulator(
                                    addCustomerAccumulator - count
                                  );
                                }

                                // update event loading
                                loadingUpdateFunc(loading - count);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}

                {viewCommand === "ADD" && (
                  <div style={{ marginTop: "10px" }}>
                    <Badge bg={"success"} style={{ marginBottom: "6px" }}>
                      Добавить пассажиров
                    </Badge>
                    <Form.Control
                      style={{ marginTop: "10px" }}
                      type="text"
                      value={addedCutomerCount}
                      placeholder="Количество ..."
                      onChange={(e) => {
                        const val = Number(
                          e.target.value.replace(/[^0-9\\.]+/g, "")
                        );
                        const maxToAdd =
                          capacity - loading - addCustomerAccumulator;
                        if (val < maxToAdd) {
                          setAddedCutomerCount(val);
                        } else {
                          setAddedCutomerCount(maxToAdd);
                        }
                      }}
                    />
                    <Form.Control
                      disabled={!addedCutomerCount}
                      style={{ marginTop: "10px" }}
                      type="text"
                      value={addedCutomerNote}
                      placeholder="Контактный телефон ..."
                      onChange={(e) => {
                        setAddedCutomerNote(e.target.value);
                      }}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {viewCommand !== "ADD" && (
                    <PlusCircle
                      disabled={
                        free === 0 ||
                        capacity - loading - addCustomerAccumulator === 0
                      }
                      color={
                        free === 0 ||
                        capacity - loading - addCustomerAccumulator === 0
                          ? "grey"
                          : "#28a745"
                      }
                      size={24}
                      style={{ marginLeft: "20px", cursor: "pointer" }}
                      onClick={(e) => {
                        if (free === 0) {
                          return;
                        }
                        setViewCommand("ADD");
                      }}
                    />
                  )}
                  {viewCommand === "ADD" &&
                    addedCutomerNote &&
                    addedCutomerCount && (
                      <CheckCircleFill
                        disabled={free === 0}
                        color={
                          addedCutomerNote && addedCutomerCount > 0
                            ? "#28a745"
                            : "grey"
                        }
                        size={24}
                        onClick={(e) => {
                          // update page customerNotes stateif
                          setCustomerNotes([
                            ...customerNotes,
                            `${addedCutomerNote} (${addedCutomerCount} пасс.)`,
                          ]);

                          // update event customerNotes
                          const cust = [];
                          for (const element of customerNotes) {
                            cust.push(element);
                          }
                          cust.push(
                            `${addedCutomerNote} (${addedCutomerCount} пасс.)`
                          );
                          customerNotesUpdateFunc(cust);

                          // update event loading
                          loadingUpdateFunc(
                            loading + addCustomerAccumulator + addedCutomerCount
                          );

                          // update addCustomerAccumulator
                          setAddCustomerAccumulator(
                            addCustomerAccumulator + addedCutomerCount
                          );

                          // clear
                          setAddedCutomerCount();
                          setAddedCutomerNote();
                          setViewCommand();
                        }}
                      />
                    )}
                  {viewCommand === "ADD" &&
                    !(addedCutomerNote && addedCutomerCount) && (
                      <XCircleFill
                        color={"#dc3545"}
                        size={24}
                        // style={{ marginLeft: "20px", cursor: "pointer" }}
                        onClick={(e) => {}}
                      />
                    )}
                </div>
              </div>
            )}
          </>
        </Col>
      </Form.Group>
    </>
  );
};

const extractCustomersFromNotes = (str) => {
  const startIndex = str.lastIndexOf("(");
  if (startIndex !== -1 && str.includes("пасс.")) {
    const substring = str.substring(startIndex);
    const numberString = substring.replace(/[()]/g, "").trim();
    const number = parseInt(numberString, 10);
    return isNaN(number) ? null : number;
  }
  return null;
};

const renderListBox = (
  label,
  valieList,
  value,
  setValues,
  command,
  modeCommand
) => {
  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2" style={{ width: "35%" }}>
          {label}
        </Form.Label>
        <Col sm="10" style={{ width: "65%" }}>
          <Form.Select
            onChange={(e) => setValues(command, e.target.value)}
            disabled={modeCommand === "VIEW"}
          >
            <option>{value}</option>
            {valieList &&
              valieList
                .filter((entity) => {
                  return entity.label !== value;
                })
                .map((entity) => (
                  <option
                    key={entity.label}
                    value={entity.label}
                    // onClick={() => setValue(command, year)}
                  >
                    {entity.label}
                  </option>
                ))}
          </Form.Select>
        </Col>
      </Form.Group>
    </>
  );
};

const renderTextField = (
  label,
  value,
  setValue,
  isNumericField,
  limits,
  command
) => {
  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2" style={{ width: "35%" }}>
          {label}
        </Form.Label>
        <Col sm="10" style={{ width: "65%" }}>
          <Form.Control
            disabled={command === "VIEW"}
            type="text"
            value={value}
            onChange={(e) => {
              if (isNumericField) {
                const val = Number(e.target.value.replace(/[^0-9\\.]+/g, ""));
                if (limits) {
                  setValue(
                    val > limits.max
                      ? limits.max
                      : val < limits.min
                      ? limits.min
                      : val
                  );
                } else {
                  setValue(val);
                }
              } else {
                setValue(e.target.value);
              }
            }}
          />
        </Col>
      </Form.Group>
    </>
  );
};

const renderFilteredOptionField = (
  caption,
  value,
  showFunc,
  filterFunc,
  resetFunc,
  command,
  setFilteredFunc,
  values,
  setTitleFunc,
  prevFieldValue
) => {
  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          {caption}
        </Form.Label>
        <Col sm="10">
          <input
            disabled={prevFieldValue}
            type="text"
            className="form-control"
            placeholder={value}
            value={value ? value : ""}
            onChange={(e) =>
              filterFunc(e.target.value, setFilteredFunc, values, setTitleFunc)
            }
            onClick={(e) => resetFunc(command)}
          />
        </Col>
      </Form.Group>
    </>
  );
};

const renderCheckBoxListField = (
  allValues,
  command,
  checkFunc,
  currentValues,
  style
) => {
  return (
    <ul className="list-group" style={style}>
      {allValues.map((el) => (
        <li
          className="list-group-item"
          style={{ display: "inline-flex" }}
          key={el}
        >
          {el}
          <input
            type="checkbox"
            aria-label="Checkbox for following text input"
            style={{ marginLeft: "10px" }}
            checked={currentValues ? currentValues.includes(el) : false}
            value={el}
            onChange={(e) => checkFunc(command, el)}
          />
        </li>
      ))}
    </ul>
  );
};

const renderCheckBoxField = (
  command,
  title,
  checkedValue,
  checkFunc,
  prevFieldValue
) => {
  return (
    <div
      className="input-group mb-3"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <span className="input-group-text">{title}</span>
      <input
        disabled={prevFieldValue}
        type="checkbox"
        aria-label="Checkbox for following text input"
        style={{ marginLeft: "10px" }}
        checked={checkedValue}
        onChange={(e) => checkFunc(command, checkedValue)}
      />
    </div>
  );
};

const renderUploadFilesField = (
  text,
  setFilesFunc,
  multyple,
  style,
  prevFieldValue,
  command
) => {
  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2" style={{ width: "35%" }}>
          {text}
        </Form.Label>
        <Col sm="10" style={{ width: "65%" }}>
          <Form.Control
            disabled={command === "VIEW"}
            onChange={(e) => setUploadedFiles(e, setFilesFunc)}
            multiple={multyple}
            type="file"
            className="custom-file-input"
          />
        </Col>
      </Form.Group>
    </>
  );
};

const hideAllExcept = (command, hideMap) => {
  hideMap.forEach((set) => {
    if (command === COMMAND_ALL) {
      set[1](false);
    } else {
      if (set[0] !== command) {
        set[1](false);
      } else {
        set[1](true);
      }
    }
  });
};

const renderField = (
  title,
  value,
  onChangeFunc,
  onClickFunc,
  onClickValue,
  command,
  hideFuncArray,
  prevFieldValue,
  isNumericField
) => {
  return (
    <div className="input-group mb-3">
      <span className="input-group-text">{title}</span>
      <input
        disabled={prevFieldValue}
        type="text"
        className="form-control"
        value={value}
        onChange={(e) => {
          setFieldState(e, isNumericField, onChangeFunc);
        }}
        onClick={() => {
          hideAllExcept(command, hideFuncArray);
          if (onClickFunc) {
            onClickFunc(onClickValue);
          }
        }}
      />
    </div>
  );
};

const setUploadedFiles = (event, setFilesFunc) => {
  console.log(event.target.files);
  setFilesFunc(event.target.files);
};

const setFieldState = (e, isNumericField, onChangeFunc) => {
  const val = e.target.value;
  if (onChangeFunc) {
    if (isNumericField) {
      const num = val.replace(/[^0-9\\.]+/g, "");
      console.log(num);
      onChangeFunc(num);
    } else {
      onChangeFunc(e.target.value);
    }
  }
};

const renderButtonBlock = (style, buttons) => {
  return <div style={style}>{buttons.map((btn) => btn)}</div>;
};

const renderCarousel = (
  arr,
  mainImage,
  setMainImage,
  deleteFlags,
  setDeleteFlags,
  command,
  style
) => {
  return (
    <>
      {arr && arr.length > 0 && (
        <div style={style}>
          <Carousel>
            {arr.map((url, index) => (
              <Carousel.Item key={index}>
                {command === "EDIT" && (
                  <div
                    style={{
                      borderRadius: "15px",
                      cursor: "pointer",
                      zIndex: "101",
                      position: "absolute",
                      backgroundColor: "white",
                      width: "80px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      opacity: "0.9",
                    }}
                  >
                    <CheckSquareFill
                      color={mainImage === index ? "green" : "grey"}
                      size={20}
                      opacity={mainImage === index ? 1 : 0.5}
                      onClick={() => {
                        if (deleteFlags[index]) {
                          setDeleteFlags((prevState) =>
                            prevState.map((item, idx) =>
                              idx === index ? false : true
                            )
                          );
                        }
                        setMainImage(index);
                      }}
                    />

                    <XCircleFill
                      color={deleteFlags[index] ? "red" : "grey"}
                      size={20}
                      opacity={deleteFlags[index] ? 1 : 0.5}
                      onClick={() => {
                        if (mainImage === index) {
                          setMainImage(-1);
                        }
                        setDeleteFlags((prevState) =>
                          prevState.map((item, idx) =>
                            idx === index ? !item : item
                          )
                        );
                      }}
                    />
                  </div>
                )}

                <img
                  key={url}
                  className="d-block w-100"
                  src={`http://78.36.4.165/velum/img/${url}`}
                  alt="First slide"
                  style={{ borderRadius: "15px" }}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
};

const generateUUID = () => {
  return uuidv4();
};

export default {
  generateUUID,
  renderCarousel,
  renderButtonBlock,
  COMMAND_AUTO_MODEL,
  COMMAND_AUTO_YEAR,
  COMMAND_AUTO_DRIVE,
  COMMAND_AUTO_BASING,
  COMMAND_AUTO_ROUTES,
  COMMAND_AUTO_CHILD_SEATS,
  COMMAND_AUTO_BONUSES,
  COMMAND_AUTO_LICENSE,
  COMMAND_AUTO_PASSENGERS,
  COMMAND_AUTO_MICROPHONE,
  COMMAND_AUTO_ANIMALS,
  COMMAND_AUTO_LUKE,
  COMMAND_AUTO_PANORAMIC_ROOF,
  COMMAND_AUTO_TOILET,
  COMMAND_AUTO_INVALIDS,
  COMMAND_BOAT_NAME,
  COMMAND_BOAT_CABIN,
  COMMAND_BOAT_SPEED,
  COMMAND_BOAT_TOILET,
  COMMAND_BOAT_REGISTRATION,
  COMMAND_BOAT_SAFETYS,
  COMMAND_CREATE_AUTO,
  COMMAND_UPDATE_AUTO,
  COMMAND_IMPORT_GOOGLE_CALENDAR,
  COMMAND_SHIP_REGISTRATION,
  COMMAND_BOAT_MICROPHONE,
  COMMAND_CREATE_SHIP,
  COMMAND_UPDATE_SHIP,
  COMMAND_BOAT_WAVE_HIGH,
  COMMAND_SHIP_SPEED,
  COMMAND_VIEW_SHIP,
  renderHTagWithText,
  renderDateField,
  renderPTagWithText,
  renderButton,
  TAG_H,
  filterColumnedValues,
  convertToDouble,
  filterValues,
  renderFilteredValuesList,
  renderAllTableValues,
  renderFilteredList,
  renderTableValues,
  renderListField,
  renderCheckBoxField2,
  renderCheckListBox,
  splitData,
  fromEnum,
  fromMultiEnum,
  toEnum,
  toMultiEnum,
  renderListBox,
  renderTextField,
  renderFilteredOptionField,
  renderCheckBoxListField,
  renderCheckBoxField,
  renderUploadFilesField,
  hideAllExcept,
  renderField,
  renderButtonBlock,
  renderCarousel,
  generateUUID,
  fromEnum2,
  renderCustomersLoading,
};
