import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";

const ActivationFailed = (props) => {
  return (
    <div className="container">
      <header className="jumbotron">
        <h3>{props.content}</h3>
      </header>
    </div>
  );
};

export default ActivationFailed;
