export const YEARS = [
  { label: "1970" },
  { label: "1971" },
  { label: "1972" },
  { label: "1973" },
  { label: "1974" },
  { label: "1975" },
  { label: "1976" },
  { label: "1977" },
  { label: "1978" },
  { label: "1979" },
  { label: "1980" },
  { label: "1981" },
  { label: "1982" },
  { label: "1983" },
  { label: "1984" },
  { label: "1985" },
  { label: "1986" },
  { label: "1987" },
  { label: "1988" },
  { label: "1989" },
  { label: "1990" },
  { label: "1991" },
  { label: "1992" },
  { label: "1993" },
  { label: "1994" },
  { label: "1995" },
  { label: "1996" },
  { label: "1997" },
  { label: "1998" },
  { label: "1999" },
  { label: "2000" },
  { label: "2001" },
  { label: "2002" },
  { label: "2003" },
  { label: "2004" },
  { label: "2005" },
  { label: "2006" },
  { label: "2007" },
  { label: "2008" },
  { label: "2009" },
  { label: "2010" },
  { label: "2011" },
  { label: "2012" },
  { label: "2013" },
  { label: "2014" },
  { label: "2015" },
  { label: "2016" },
  { label: "2017" },
  { label: "2018" },
  { label: "2019" },
  { label: "2020" },
  { label: "2021" },
  { label: "2022" },
  { label: "2023" },
  { label: "2024" },
  { label: "2025" },
];
export const allDrives = ["Полный", "Передний", "Задний"];

export const PROMPTS = [
  { value: "NONE", label: "нет" },
  { value: "MIN_10", label: "за 10 мин." },
  { value: "MIN_15", label: "за 15 мин." },
  { value: "MIN_30", label: "за 30 мин." },
  { value: "HOUR_1", label: "за 1 час." },
  { value: "HOUR_2", label: "за 2 час." },
  { value: "HOUR_3", label: "за 3 час." },
  { value: "HOUR_4", label: "за 4 час." },
  { value: "HOUR_6", label: "за 6 час." },
  { value: "HOUR_8", label: "за 8 час." },
  { value: "HOUR_12", label: "за 12 час." },
  { value: "HOUR_24", label: "за 24 час." },
];
export const DRIVES = [
  { value: "ALL_WHEEL_DRIVE", label: "Полный", order: 1 },
  { value: "FRONT_WHEEL_DRIVE", label: "Передний", order: 2 },
  { value: "REAR_WHEEL_DRIVE", label: "Задний", order: 3 },
];
export const ROUTES = [
  { value: "MURMANSK_REGION", label: "Мурманская область", order: 1 },
  { value: "KARELIA_REGION", label: "Республика Карелия", order: 2 },
  { value: "MOSCOW_REGION", label: "Московская область", order: 3 },
];

export const EVENT_LOCATION = [
  { value: "MURMANSK", label: "Мурманск", order: 1 },
  { value: "TERIBERKA", label: "Териберка", order: 2 },
  { value: "APATITY", label: "Апатиты", order: 3 },
  { value: "KIROVSK", label: "Кировск", order: 4 },
  { value: "KANDALAKSHA", label: "Кандалакша", order: 5 },
];
export const LOCATION = [
  { value: "ALAKURTTI", label: "Алакуртти", order: 1000 },
  { value: "APATITY", label: "Апатиты", order: 3 },
  { value: "AFRIKANDA", label: "Африканда", order: 1000 },
  { value: "BELOYE_MORE", label: "Белое Море", order: 1000 },
  { value: "BELOKAMENKA", label: "Белокаменка", order: 1000 },
  { value: "VARZUGA", label: "Варзуга", order: 1000 },
  { value: "VERKHNETULOMSKIY", label: "Верхнетуломский", order: 1000 },
  { value: "VIDYAYEVO", label: "Видяево", order: 1000 },
  { value: "GADZHIYEVO", label: "Гаджиево", order: 1000 },
  { value: "ENSKIY", label: "Енский", order: 1000 },
  { value: "ZAOZERSK", label: "Заозерск", order: 1000 },
  { value: "ZAPOLYARNYY", label: "Заполярный", order: 1000 },
  { value: "ZARECHENSK", label: "Зареченск", order: 1000 },
  { value: "ZASHEYEK", label: "Зашеек", order: 1000 },
  { value: "ZVEROSOVKHOZ", label: "Зверосовхоз", order: 1000 },
  { value: "ZELENOBORSKIY", label: "Зеленоборский", order: 1000 },
  { value: "KANDALAKSHA", label: "Кандалакша", order: 5 },
  { value: "KANEVKA", label: "Каневка", order: 1000 },
  { value: "KASHKARANTSY", label: "Кашкаранцы", order: 1000 },
  { value: "KILPYAVR", label: "Килпъявр", order: 1000 },
  { value: "KILDINSTROY", label: "Кильдинстрой", order: 1000 },
  { value: "KIROVSK", label: "Кировск", order: 4 },
  { value: "KOASHVA", label: "Коашва", order: 1000 },
  { value: "KOVDA_STANTSIYA", label: "Ковда-Станция", order: 1000 },
  { value: "KOVDOZERO", label: "Ковдозеро", order: 1000 },
  { value: "KOVDOR", label: "Ковдор", order: 1000 },
  { value: "KOLA", label: "Кола", order: 1000 },
  { value: "KORZUNOVO", label: "Корзуново", order: 1000 },
  { value: "KRASNOSHCHELYE", label: "Краснощелье", order: 1000 },
  { value: "KUZOMEN", label: "Кузомень", order: 1000 },
  { value: "KUROPTA", label: "Куропта", order: 1000 },
  { value: "LAPLANDIYA", label: "Лапландия", order: 1000 },
  { value: "LEYPI", label: "Лейпи", order: 1000 },
  { value: "LESOZAVODSKIY", label: "Лесозаводский", order: 1000 },
  { value: "LIINAKHAMARI", label: "Лиинахамари", order: 1000 },
  { value: "LOVOZERO", label: "Ловозеро", order: 1000 },
  { value: "LOPARSKAYA", label: "Лопарская", order: 1000 },
  { value: "LUVENGA", label: "Лувеньга", order: 1000 },
  { value: "LUOSTARI", label: "Луостари", order: 1000 },
  { value: "MAGNETITY", label: "Магнетиты", order: 1000 },
  { value: "MEZHDURECHYE", label: "Междуречье", order: 1000 },
  { value: "MINKINO", label: "Минькино", order: 1000 },
  { value: "MISHUKOVO", label: "Мишуково", order: 1000 },
  { value: "MOLOCHNYY", label: "Молочный", order: 1000 },
  { value: "MONCHEGORSK", label: "Мончегорск", order: 1000 },
  { value: "MURMANSK", label: "Мурманск", order: 1 },
  { value: "MURMASHI", label: "Мурмаши", order: 1000 },
  { value: "NIVSKIY", label: "Нивский", order: 1000 },
  { value: "NIKEL", label: "Никель", order: 1000 },
  { value: "OKTYABRSKIY", label: "Октябрьский", order: 1000 },
  { value: "OLENEGORSK", label: "Оленегорск", order: 1000 },
  { value: "OLENITSA", label: "Оленица", order: 1000 },
  { value: "OSTROVNOY", label: "Островной", order: 1000 },
  { value: "PESCHANYY", label: "Песчаный", order: 1000 },
  { value: "PECHENGA", label: "Печенга", order: 1000 },
  { value: "POLYARNYY", label: "Полярный", order: 1000 },
  { value: "POYAKONDA", label: "Пояконда", order: 1000 },
  { value: "PRIRECHNYY", label: "Приречный", order: 1000 },
  { value: "PUSHNOY", label: "Пушной", order: 1000 },
  { value: "PYAYVE", label: "Пяйве", order: 1000 },
  { value: "RAYAKOSKI", label: "Раякоски", order: 1000 },
  { value: "REVDA", label: "Ревда", order: 1000 },
  { value: "RIKOLATVA", label: "Риколатва", order: 1000 },
  { value: "ROSLYAKOVO", label: "Росляково", order: 1000 },
  { value: "SAFONOVO", label: "Сафоново", order: 1000 },
  { value: "SEVEROMORSK", label: "Североморск", order: 1000 },
  { value: "SNEZHNOGORSK", label: "Снежногорск", order: 1000 },
  { value: "SOSNOVKA", label: "Сосновка", order: 1000 },
  { value: "TERIBERKA", label: "Териберка", order: 2 },
  { value: "TETRINO", label: "Тетрино", order: 1000 },
  { value: "TITAN", label: "Титан", order: 1000 },
  { value: "TULOMA", label: "Тулома", order: 1000 },
  { value: "TUMANNYY", label: "Туманный", order: 1000 },
  { value: "UMBA", label: "Умба", order: 1000 },
  { value: "URA_GUBA", label: "Ура-Губа", order: 1000 },
  { value: "CHAVANGA", label: "Чаваньга", order: 1000 },
  { value: "CHAPOMA", label: "Чапома", order: 1000 },
  { value: "SHONGUY", label: "Шонгуй", order: 1000 },
];

export const CHILD_SEATS = [
  { label: "До 1 года", value: "UP_TO_1_YEAR", order: 1 },
  { label: "До 3 лет", value: "UP_TO_3_YEAR", order: 2 },
  { label: "До 12 лет", value: "UP_TO_12_YEAR", order: 3 },
];
export const BONUSES = [
  { label: "Кофе", value: "COFFEE", order: 1 },
  { label: "Чай", value: "TEA", order: 2 },
  { label: "Закуски", value: "SNACK", order: 3 },
  { label: "Вода", value: "WATER", order: 4 },
];

export const LANGUAGES = [
  { label: "CHINESE", value: "Китайский", order: 1 },
  { label: "SPANISH", value: "Испанский", order: 2 },
  { label: "ENGLISH", value: "Английский", order: 3 },
  { label: "PORTUGUESE", value: "Португальский", order: 4 },
  { label: "JAPANESE", value: "Японский", order: 5 },
  { label: "FRENCH", value: "Французский", order: 6 },
  { label: "GERMAN", value: "Немецкий", order: 7 },
  { label: "SWEDISH", value: "Шведский", order: 8 },
  { label: "NORWEGIAN", value: "Норвежский", order: 9 },
  { label: "FINNISH", value: "Финский", order: 10 },
  { label: "ARABIC", value: "Арабский", order: 11 },
  { label: "HINDI", value: "Хинди", order: 12 },
  { label: "THAI", value: "Тайский", order: 13 },
];

export const getDriveByTitle = (title) => {
  switch (title) {
    case "Полный":
      return "ALL_WHEEL_DRIVE";
      break;
    case "Передний":
      return "FRONT_WHEEL_DRIVE";
      break;
    case "Задний":
      return "REAR_WHEEL_DRIVE";
  }
};
export const getTitleByDrive = (drive) => {
  switch (drive) {
    case "ALL_WHEEL_DRIVE":
      return "Полный";
    case "FRONT_WHEEL_DRIVE":
      return "Передний";
    case "REAR_WHEEL_DRIVE":
      return "Задний";
  }
};
export const allBasing = ["Мурманск", "Апатиты", "Оленегорск", "Териберка"];
export const allRoutes = [
  "Мурманская область",
  "Карелия",
  "Московкая область",
  "Москва",
];
export const allChildSeats = ["до 1 года", "до 3 лет", "до 12 лет"];
export const allBonuses = ["Кофе", "Чай", "Закуски", "Вода"];
export const locations = [
  { label: "Мурманск" },
  { label: "Апатиты" },
  { label: "Териберка" },
  { label: "Рыбачий" },
];
export const prompts = [
  { label: "за 10 мин." },
  { label: "за 15 мин." },
  { label: "за 30 мин." },
  { label: "за 1 час." },
  { label: "за 2 час." },
  { label: "за 3 час." },
  { label: "за 4 час." },
  { label: "за 6 час." },
  { label: "за 8 час." },
  { label: "за 12 час." },
  { label: "за 24 час." },
];
