import {
  SET_MESSAGE,
  CLEAR_MESSAGE,
  SELECT_SERVICE,
  SET_COMMAND_FOR_SELECTED_SERVICE,
} from "./types";

export const setMessage = (message) => ({
  type: SET_MESSAGE,
  payload: message,
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});

export const setSelectedServiceId = (message) => ({
  type: SELECT_SERVICE,
  payload: message,
});

export const setCommandForSelectedService = (message) => ({
  type: SET_COMMAND_FOR_SELECTED_SERVICE,
  payload: message,
});
