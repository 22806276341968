import Button from "react-bootstrap/Button";
import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";

const DateTimePicker = (props) => {
  const [dateTime, setDateTime] = useState(props.dateTime);

  return (
    <>
      <DatePicker
        locale={ru}
        showTimeSelect
        timeFormat="p"
        dateFormat="Pp"
        timeIntervals={30}
        showIcon
        selected={dateTime}
        onChange={(dt) => {
          setDateTime(dt);
          props.update(props.eventId, props.command, dt);
        }}
        timeCaption="время"
        mo
      />
    </>
  );
};

export default DateTimePicker;
