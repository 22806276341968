import { useState, useRef } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Button from "react-bootstrap/Button";

const DayPicker = (props) => {
  const picker = useRef();

  return (
    <div>
      <Calendar
        onActiveStartDateChange={(e) => props.onActiveDateChange(e)}
        ref={picker}
        activeStartDate={props.startDate}
        onChange={(e) => props.onChangeDay(e)}
        value={props.currentDate ? props.currentDate : new Date()}
      />
    </div>
  );
};
export default DayPicker;
