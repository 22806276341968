let eventGuid = 100;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today
export const UPDATE_EVENT_START = 0;
export const UPDATE_EVENT_END = 1;
export const UPDATE_EVENT_TITLE = 2;
export const UPDATE_EVENT_DESCRIPTION = 3;
export const UPDATE_EVENT_PROMPT = 4;
export const UPDATE_EVENT_LOCATION = 5;
export const UPDATE_EVENT_ON_SERVER = 6;
export const SCHEDULER_PREV = 7;
export const SCHEDULER_NEXT = 8;
export const SCHEDULER_TODAY = 9;
export const SCHEDULER_MONTH = 10;
export const SCHEDULER_WEEK = 11;
export const SCHEDULER_DAY = 12;
export const UPDATE_EVENT_LOADING = 13;
export const UPDATE_EVENT_CUSTOMER_NOTES = 14;
export const UPDATE_EVENT_SERVICE_ID = 15;
export const VIEW_TYPE_MONTH = "dayGridMonth";
export const VIEW_TYPE_WEEK = "timeGridWeek";
export const VIEW_TYPE_DAY = "timeGridDay";
export const VIEW_TYPE_GRID = "timeGrid";

export const getFirstMonthDay = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const formatDate = (date) => {
  return date.toISOString().replace(/T.*$/, "");
};

export const INITIAL_EVENTS = [
  {
    id: 0,
    title: "Знакомство с Рыбачьим",
    start: "2024-11-02T01:30:00",
    end: "2024-11-02T02:30:00",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prompt: "за 30 мин.",
    location: "Рыбачий",
  },

  {
    id: 1,
    title: "Знакомство с Рыбачьим",
    start: "2024-11-02T04:30:00",
    end: "2024-11-02T04:30:00",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prompt: "за 30 мин.",
    location: "Рыбачий",
  },
  {
    id: 2,
    title: "Знакомство с Рыбачьим",
    start: "2024-11-02T10:30:00",
    end: "2024-11-02T12:30:00",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prompt: "за 30 мин.",
    location: "Рыбачий",
  },
  {
    id: 3,
    title: "Прекрасная Териберка",
    start: "2024-11-01T13:30:00",
    end: "2024-11-01T15:30:00",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prompt: "за 30 мин.",
    location: "Териберка",
  },
  {
    id: 4,
    title: "Знакомство с Рыбачьим",
    start: "2024-11-02T10:30:00",
    end: "2024-11-02T12:30:00",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prompt: "за 30 мин.",
    location: "Рыбачий",
  },
  {
    id: 5,
    title: "Прекрасная Териберка",
    start: "2024-11-01T13:30:00",
    end: "2024-11-01T15:30:00",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prompt: "за 30 мин.",
    location: "Териберка",
  },

  {
    id: 6,
    title: "Знакомство с Рыбачьим",
    start: "2024-11-02T10:30:00",
    end: "2024-11-02T12:30:00",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prompt: "за 30 мин.",
    location: "Рыбачий",
  },
  {
    id: 7,
    title: "Прекрасная Териберка",
    start: "2024-11-01T13:30:00",
    end: "2024-11-01T15:30:00",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prompt: "за 30 мин.",
    location: "Териберка",
  },
  {
    id: 8,
    title: "Знакомство с Рыбачьим",
    start: "2024-11-02T10:30:00",
    end: "2024-11-02T12:30:00",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prompt: "за 30 мин.",
    location: "Рыбачий",
  },
  {
    id: 9,
    title: "Прекрасная Териберка",
    start: "2024-11-01T13:30:00",
    end: "2024-11-01T15:30:00",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    prompt: "за 30 мин.",
    location: "Териберка",
  },
];

export function createEventId() {
  return String(eventGuid++);
}
