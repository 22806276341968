import {
  SET_MESSAGE,
  CLEAR_MESSAGE,
  SET_COMMAND_FOR_SELECTED_SERVICE,
  SELECT_SERVICE,
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return { message: payload };

    case CLEAR_MESSAGE:
      return { message: "" };

    case SELECT_SERVICE:
      return { message: payload };

    default:
      return state;
  }
}
