import axios from "axios";
import { authId, authHeader } from "./auth-header";

const API_URL = "http://78.36.4.165:8080/api/test/";
const SHIP_URL = `http://78.36.4.165:8080/api/ships/${authId()}`;
const EVENTS_URL = `http://78.36.4.165:8080/api/events/`;
const IMAGE_URL = `http://78.36.4.165:8080/api/ships/images/`;
const ERRORS_URL = `http://78.36.4.165:8080/api/errors/`;

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

const createShip = (ship) => {
  return axios.post(SHIP_URL, ship, {
    headers: authHeader(),
  });
};

const uploadImages = (formData, id) => {
  return fetch(IMAGE_URL + id, {
    method: "POST",
    body: formData,
    headers: authHeader(),
  });
  return axios.post(
    IMAGE_URL + id,
    { images: formData },
    {
      headers: authHeader(),
    }
  );
};

const updateShip = (ship) => {
  return axios.put(SHIP_URL, ship, {
    headers: authHeader(),
  });
};

const getShip = (id) => {
  return axios.get(SHIP_URL + "/" + id, {
    headers: authHeader(),
  });
};

const getShipName = (id) => {
  return axios.get(SHIP_URL + "/name/" + id, {
    headers: authHeader(),
  });
};

const getShipIdAndNames = () => {
  return axios.get(SHIP_URL + "/names/", {
    headers: authHeader(),
  });
};

const getImages = (name) => {
  return axios.get(IMAGE_URL + name, {
    responseType: "arraybuffer",
    headers: authHeader(),
  });
};

const getShips = () => {
  return axios.get(SHIP_URL, {
    headers: authHeader(),
  });
};

const getShipsDTO = () => {
  return axios.get(SHIP_URL + "/dto", {
    headers: authHeader(),
  });
};

const deleteShip = (id) => {
  return axios.delete(SHIP_URL + "/" + id, {
    headers: authHeader(),
  });
};

const getEvents = (period, data) => {
  return axios.get(EVENTS_URL + period + "/" + data + "/" + authId(), {
    headers: authHeader(),
  });
};

const getNextEventId = () => {
  return axios.get(EVENTS_URL + "next", {
    headers: authHeader(),
  });
};

const createEvent = (event) => {
  return axios.post(EVENTS_URL + authId(), event, {
    headers: authHeader(),
  });
};

const deleteEvent = (id) => {
  return axios.delete(EVENTS_URL + authId() + "/" + id, {
    headers: authHeader(),
  });
};

// const sendError = (error) => {
//   return axios.get(ERRORS_URL, {
//     headers: authHeader(),
//   });
// };

// const addCustomersToEvent = (id, customers, contact) => {
//   return axios.get(
//     EVENTS_URL + "add_customer/" + id + "/" + customers + "/" + contact,
//     {
//       headers: authHeader(),
//     }
//   );
// };

const updateEvent = (event) => {
  return axios.put(EVENTS_URL + authId(), event, {
    headers: authHeader(),
  });
};

export default {
  getShipName,
  deleteEvent,
  // sendError,
  // addCustomersToEvent,
  getNextEventId,
  createEvent,
  updateEvent,
  getEvents,
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  createShip,
  getShips,
  getShip,
  deleteShip,
  updateShip,
  uploadImages,
  getImages,
  getShipIdAndNames,
  getShipsDTO,
};
